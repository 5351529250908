import api from '../../utils/api'

// 授权
export async function main_info (payload) {
  return api.get(`/api/v1/mp/main`, payload)
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/main`,payload)
}

export async function family_info (payload) {
  return api.get(`/api/v1/mp/families/relationships`, payload)
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/families/relationships`,payload)
}
