<template>
  <div class="g_page_box">
    <div class="g_main_content" style="background: #fff">
      <g-loading :loading="loading" />
      <div class="head">
        <div class="head_info">
          <img
            :src="data.avatar_url || './img/family-imgs/img-user-avatar.png'"
            alt=""
          />
          <div style="flex: 1">
            <div class="head_name">
              {{ data.nickname }}
              <div class="logout" @click="logout">退出</div>
            </div>
            <p>{{ user.phone }} {{ format_birthDay(user.birthday) }}岁</p>
          </div>
        </div>
        <router-link :to="{ path: '/health-record/2' }">
          <div class="health_btn">
            <van-icon name="envelop-o" color="#0088ff" size="14px" />
            <span>健康档案</span>
          </div>
        </router-link>
      </div>

      <div class="health">
        <div class="health_title">我的服务</div>
        <div class="health_box">
          <router-link :to="{ path: '/registration-record' }">
            <div class="health_items">
              <img alt="" src="../../assets/home/19@2x.png" width="30" />
              <p>我的挂号</p>
            </div>
          </router-link>

          <router-link :to="{ path: '/my-check' }">
            <div class="health_items">
              <img alt="" src="../../assets/home/1@3x.png" width="30" />
              <p>我的检查</p>
            </div>
          </router-link>
          <router-link :to="{ path: '/my-inspection' }">
            <div class="health_items">
              <img alt="" src="../../assets/home/2@3x.png" width="30" />
              <p>我的检验</p>
            </div>
          </router-link>

          <div class="health_items" @click="onTapOrder()">
            <img alt="" src="../../assets/home/12@2x.png" width="30" />
            <p>购物订单</p>
          </div>
        </div>
      </div>

      <div class="family">
        <div class="family_tabs">
          <p>我的家庭</p>
          <router-link :to="{ path: '/my-family' }">
            <div class="manage_btn">管理家庭</div>
          </router-link>
        </div>
        <div class="family_item_box">
          <div
            class="family_items"
            :class="idx === familyIndex ? 'active' : ''"
            v-for="(item, idx) in members"
            :key="item.id"
            @click="familyChange(idx, item)"
          >
            <div class="img_out">
              <img
                :src="get_user_head(item.relationship, item.gender)"
                width="45"
                alt=""
              />
            </div>
            <div class="family_name">
              {{ item.relationship_label }}
            </div>
          </div>
        </div>
      </div>
      <div class="slider_out">
        <div class="slide_card" @click="onActivedTap()">
          <div class="card_icon">特别推荐</div>
          <div class="card_title">敬老月,爸妈的“健康大礼包”领了吗？</div>
          <div class="card_text">
            <div class="card_point"></div>
            <p>免费健康体检及健康管理</p>
          </div>
          <div class="card_text">
            <div class="card_point"></div>
            <p>免费领取随身急救药包一个</p>
          </div>
          <div class="card_text">
            <div class="card_point"></div>
            <p>免费理疗（针灸、艾灸、推拿和刮痧，4选1）2次。</p>
          </div>

          <div class="card_box">
            <div class="box_title">65岁以上老年人 (含65岁)均可参加</div>
            <div class="box_text">免费健康体检及健康管理</div>
            <div class="box_btn">活动详情</div>
          </div>
        </div>

        <!-- <div class="slide_card">
          <div class="card_icon">特别推荐</div>
          <div class="card_title">健康评估，防范重大疾病</div>
          <div class="card_text">
            <div class="card_point"></div>
            <p>肿瘤排筛</p>
          </div>
          <div class="card_text">
            <div class="card_point"></div>
            <p>肿瘤排筛</p>
          </div>

          <div class="card_box">
            <div class="box_title">白领健康体检套餐</div>
            <div class="box_text">36项健康检查，中年人常见疾病排筛</div>
            <div class="box_btn">159元</div>
          </div>
        </div> -->
      </div>

      <div class="health">
        <div class="health_title">个人设置</div>
        <div class="health_box">
          <router-link :to="{ path: '/registration-record' }">
            <div class="health_items">
              <img alt="" src="../../assets/home/20@2x.png" width="30" />
              <p>个人信息</p>
            </div>
          </router-link>
          <router-link :to="{ path: '/registration-record' }">
            <div class="health_items">
              <img alt="" src="../../assets/home/21@2x.png" width="30" />
              <p>健康卡</p>
            </div>
          </router-link>

          <router-link :to="{ path: '/registration-record' }">
            <div class="health_items">
              <img alt="" src="../../assets/home/18@2x.png" width="30" />
              <p>我的地址</p>
            </div>
          </router-link>
          <router-link :to="{ path: '/registration-record' }">
            <div class="health_items">
              <img alt="" src="../../assets/home/22@2x.png" width="30" />
              <p>停车记录</p>
            </div>
          </router-link>
          <router-link :to="{ path: '/registration-record' }">
            <div class="health_items">
              <img alt="" src="../../assets/home/16@2x.png" width="30" />
              <p>二维码名片</p>
            </div>
          </router-link>
          <router-link :to="{ path: '/registration-record' }">
            <div class="health_items">
              <img alt="" src="../../assets/home/15@2x.png" width="30" />
              <p>条形码名片</p>
            </div>
          </router-link>
          <router-link :to="{ path: '/registration-record' }">
            <div class="health_items">
              <img alt="" src="../../assets/home/14@2x.png" width="30" />
              <p>食堂订单</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { main_info, family_info } from "./service";
import { format_birthDay } from "../../utils/format";
import { get_user_head } from "../../utils/imgUrl";
import { removeSession } from "../../utils/session";
import { getSession } from "@/utils/session";
import { logout } from "../login/service";
import { im_logout } from "../../im";

export default {
  data() {
    return {
      familyIndex: 0,
      data: {},
      loading: false,
      user: {},
      members: [],
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    this.main();
    this.get_user();
  },
  methods: {
    onTapOrder() {
      let link =
        "https://guodadrugstores.cn/ucenter/luohu/index?wxID=6&appid=123&backurl=https%3A%2F%2Fguodadrugstores.cn%2Fv2%2F%23%2Fuser%2Forder%2Flist%3Fa%3D1&user_key=" +
        getSession().user_id;

      //alert(link);
      window.location.href = link;
    },
    format_birthDay,
    get_user_head,
    async main() {
      this.loading = true;
      try {
        const { data } = await main_info();
        this.data = data;
      } finally {
        this.loading = false;
      }
    },
    familyChange(idx) {
      this.familyIndex = idx;
    },
    async get_user() {
      this.loading = true;
      try {
        const { data } = await family_info();
        this.members = data.members;
        this.set_user(data);
      } finally {
        this.loading = false;
      }
    },
    onActivedTap() {
      window.location.href = `https://mp.weixin.qq.com/s/6Iy_rmduCBAVi17ktK2_-w`;
    },
    set_user(data) {
      data.members.forEach((el) => {
        if (el.default_member) {
          this.user = el;
        }
      });
    },
    async logout() {
      try {
        await logout();
        // 退出im
        await im_logout();
      } finally {
        removeSession();
        this.$router.push({ path: "/" });
      }
    },
  },
};
</script>

<style scoped>
.head {
  font-weight: 400;
  color: #242938;
  border-bottom: solid 10px rgba(0, 0, 0, 0.03);
  line-height: 17px;
  font-size: 12px;
  padding-bottom: 10px;
  background-image: url("../../assets/img/personal-center/img_person_header_bg.png");
  background-repeat: no-repeat;
  background-position: -20px -40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.head_info {
  display: flex;
  align-items: center;
  padding: 15px 12px;
  & img {
    margin-right: 10px;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    border: 3px #fff solid;
    -webkit-box-shadow: 00px 0px 10px rgba($color: #000000, $alpha: 0.1);
    box-shadow: 00px 0px 10px rgba($color: #000000, $alpha: 0.1);
  }
  & .logout {
    display: inline-block;
    padding: 0px 10px;
    color: #0088ff;
    font-size: 12px;
    margin-left: 5px;
  }
}

.head_name {
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
}

.health_btn {
  margin-right: 14px;
  font-size: 13px;
  padding: 4px 10px;
  border: solid 1px #0088ff;
  border-radius: 15px;
  color: #0088ff;
  display: flex;
  align-items: center;

  & span {
    margin-left: 2px;
  }
}

.family {
  padding: 0px 16px;
  display: flex;
  flex-direction: column;

  & .family_tabs {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    color: #242938;
    padding-top: 16px;
  }

  & .family_item_box {
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    height: 95px;
    color: #242938;
    overflow-x: auto;
    display: -webkit-box;
    padding-bottom: 15px;
  }

  & .manage_btn {
    width: 78px;
    height: 23px;
    font-size: 13px;
    line-height: 23px;
    color: #0088ff;
    padding: 0 10px;
    position: relative;
  }

  & .family_name {
    padding: 0 10px;
    height: 17px;
    background: #ffa700;
    opacity: 1;
    border-radius: 9px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #ffffff;
    margin-top: -2px;
  }
}

.family_items {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-top: 18px;
  width: 18vw;

  &.active .img_out {
    border-color: #0088ff;
  }
}

.img_out {
  width: 47px;
  height: 47px;
  background: #edf5ff;
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 28px;
  text-align: center;
}

.slider_out {
  padding: 0px 0 0px 16px;
  display: -webkit-box;
  overflow-x: auto;
  padding-bottom: 20px;
  border-bottom: solid 10px rgba(0, 0, 0, 0.03);
}

.slide_card {
  width: 77vw;
  margin-right: 12px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.06);
  opacity: 1;
  border-radius: 5px;
  padding: 0 14px 16px;
  color: #242938;
}

.card_icon {
  width: 70px;
  height: 21px;
  background: #0088ff;
  border-radius: 0px 0px 2px 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
}

.card_title {
  margin-top: 12px;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  color: #242938;
}

.card_text {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #8c8c8c;
  display: flex;
  align-items: center;
}

.card_point {
  width: 5px;
  height: 5px;
  background: #919191;
  border-radius: 50%;
  margin-right: 8px;
}

.card_box {
  background: #f4f9fe;
  padding: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #1a94ff;
  margin-top: 9px;
}

.box_title {
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  color: #242938;
  margin-bottom: 5px;
}

.box_btn {
  width: 61px;
  height: 25px;
  background: #0088ff;
  opacity: 1;
  border-radius: 13px;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  color: #ffffff;
  margin-top: 5px;
}

.health {
  padding: 6px 16px;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 24px;
  color: #242938;
  border-bottom: solid 0.1rem rgba(0, 0, 0, 0.03);
}

.health_box {
  padding: 10px 0;
  font-size: 13px;
  line-height: 16px;
  color: #242938;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  & a {
    width: 25%;

    & .health_items {
      width: 100%;
    }
  }
}
.health_title {
  margin-top: 10px;
}
.health_items {
  width: 25%;
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  p {
  }
  & img {
    margin-bottom: 8px;
  }
}
</style>
